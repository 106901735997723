import { ParallaxProvider } from 'react-scroll-parallax';

import { LandingPageV2Props } from 'cms';
import landingPageV2EntryLookup from 'cms/entries/landingPageV2';
import RichTextRenderer from 'cms/renderer';

import { CacheSafeGetServerSideProps } from 'declarations/shared/serverSideProps';

import { ReturnExperienceContextProvider } from 'helpers/ReturnExperienceContext';
import { StickyCTABarContextProvider } from 'helpers/StickyCTABarContext';

import LandingPageV2Layout, {
  getLandingpageV2ServerSideProps,
} from 'pages/shared/LandingPageV2Layout';

export const getServerSideProps: CacheSafeGetServerSideProps<LandingPageV2Props> = async (
  context,
) => getLandingpageV2ServerSideProps(context);

// don't care about 'any' here because LandingPage has proper typing
const LandingPageWithContext = (props: LandingPageV2Props) => {
  return (
    <ParallaxProvider>
      <ReturnExperienceContextProvider
        showReturnExperienceBanner={props.fields.showReturnExperienceBanner}
      >
        <StickyCTABarContextProvider>
          <LandingPageV2Layout {...props}>
            <RichTextRenderer.Component
              body={props.fields.body}
              idToLoaderData={props.idToLoaderData}
              entryLookup={landingPageV2EntryLookup}
            />
          </LandingPageV2Layout>
        </StickyCTABarContextProvider>
      </ReturnExperienceContextProvider>
    </ParallaxProvider>
  );
};

export default LandingPageWithContext;
